import axios from '@axios'

export default {
  namespaced: true,
  state: {
    cartItemsCount: (() => {
      const userData = JSON.parse(localStorage.getItem('userData'))
      return userData && userData.extras ? userData.extras.eCommerceCartItemsCount : 0 })(),
      cartItems: []
  },
  getters: {},
  mutations: {
    EMPTY_CART(state) {
      state.cartItems = []
    },
    REMOVE_PRODUCT_FROM_CART(state, productId) {
      state.cartItems.splice(state.cartItems.findIndex(item => item.id === productId), 1)
    },
    UPDATE_CART_ITEMS_COUNT(state, count) {
      state.cartItemsCount = count
    },
    UPDATE_CART_ITEMS(state, data) {
      state.cartItems = data
    },
    CART_ITEMS(state, data) {
      for (let i = 0; i < data.length; i++) {
        this.commit('app-ecommerce/UPDATE_PRODUCT_CART', data[i])
      }
    },
    UPDATE_PRODUCT_CART(state, data) {
      // Verifica que 'data' no sea null o undefined
      if (data && (typeof data.id === 'string' || data.id instanceof String)) {
        const menu = state.cartItems.find(e => e.id == data.id);
        if (!menu) {
          state.cartItems.push(data);
        }
      } else if (data && typeof data.id !== 'undefined') {
        const product = state.cartItems.find(e => e.id == data.id);
        if (!product) {
          state.cartItems.push(data);
        }
      }
    }
  },
  actions: {
    fetchCartProducts({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.get('/api/apps/ecommerce/fetch-cart-products', { params: payload })
          .then(response => {
            commit('CART_ITEMS', response.data)
            commit('UPDATE_CART_ITEMS_COUNT', response.data.length)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    addCartProducts(ctx, products) {
     return new Promise((resolve, reject) => {
      axios.post('/api/apps/ecommerce/add-cart-products', products)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
      })
    },
  },
}
